import { put, select, takeEvery } from 'typed-redux-saga';
import { OPEN_SIDE_OUT_TASKS_BULK, TasksBulkActions } from './tasksBulkActions';
import { requestDeleteTaskBulk, requestTasksBulkHistory } from 'api/task/tasksBulk/tasksBulkActions';
import { selectTasksBulkHistory } from './tasksBulkSelectors';
import { TasksBulk } from 'api/task/tasksBulk/tasksBulkModel';
import { requestTasksBulkUpload } from 'api/files/filesActions';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';
import { browserHistory, Routes } from 'components/router/model';
import { generatePath } from 'react-router';

function* getSuggestionsReset() {
  yield* takeEvery([OPEN_SIDE_OUT_TASKS_BULK],
      function* ({ payload }: TasksBulkActions) {
        if(!payload) return;

        yield put(requestTasksBulkHistory.init(''));
      },
  );
}

function* deleteTaskBulkWatcherSaga() {
  yield* takeEvery([requestDeleteTaskBulk.successType],
      function* ({ payload }: ReturnType<typeof requestDeleteTaskBulk.success>) {
        const tasksBulk: TasksBulk = yield* select(selectTasksBulkHistory);
        const removeTaskBulk = tasksBulk.filter((task)=> task.id !== payload.id);

        yield put(requestTasksBulkHistory.success(removeTaskBulk));
      },
  );
}

function* uploadFileTaskBulkWatcherSaga() {
  yield* takeEvery([requestTasksBulkUpload.successType],
      function* () {
        browserHistory.push(generatePath(Routes.TaskList));
        yield put(showToastMessage({
          message: `${i18n.t('File uploaded successfully.')}`, options: { variant: ToastVariant.Success },
        }));
      },
  );
}

export const taskBulkSagas = [
  getSuggestionsReset,
  deleteTaskBulkWatcherSaga,
  uploadFileTaskBulkWatcherSaga
];
