import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTasksBulkHistory, selectTasksBulkHistoryFetching } from 'store/modules/task/TasksBulk/tasksBulkSelectors';
import { makeSkeletons } from 'modules/shared/components/gridHelpers';
import { TaskBulk } from 'api/task/tasksBulk/tasksBulkModel';
import { fullDateByBrowser } from 'utils/helpers/dateHelper';
import { useBulkTasksStyles } from './useBulkTasksStyles';
import { Skeleton } from '@material-ui/lab';
import { StyledBulkTasksPanelTitle } from './BulkTasksPanel';
import { RemoveBulkTask } from './RemoveBulkTasks';
import { TasksBulkHistoryActionCell } from './TasksBulkHistoryActionCell';
import { UserInfo } from 'components/userInfo/UserInfo';
import { TaskBulkHistoryFileName } from './TaskBulkHistoryFileName';

interface Column {
  id: 'id' | 'createdAt' | 'fileName'| 'createdBy';
  label: React.ReactNode;
  width?: number;
  align?: 'right' | 'center' | 'left';
  customCell?: (value: string, fileName: string) => string | React.ReactNode;
}
const columns: Column[] = [
  { 
    id: 'createdBy',
    label: <Trans>User</Trans>,
    customCell: (value: string) => <UserInfo userId={value} showInformation={false}/>,
  },
  { 
    id: 'createdAt',
    label: <Trans>Date</Trans>,
    customCell: (value: string) => fullDateByBrowser(value),
  },
  { 
    id: 'fileName',
    label: <Trans>File Name</Trans>,
    customCell: (value: string) => <TaskBulkHistoryFileName fileName={value} />,
  },
  {
    id: 'id',
    label: <Trans>Action</Trans>,
    align: 'right',
    customCell: (value: string, fileName: string) => <TasksBulkHistoryActionCell id={value} fileName={fileName} />,
    width: 60
  },
];

const skeletonsColumn = makeSkeletons<TaskBulk>(3);
const skeletonsRow = makeSkeletons<TaskBulk>(4);

const BodyRowSkeletonRenderer = () => {
  const classes = useBulkTasksStyles();
  return(
    <>
      {skeletonsColumn.map((column) => (
        <TableRow hover tabIndex={-1} key={`col-skeleton-${column.id}`}>
        {skeletonsRow.map((row) => (
          <TableCell className={classes.tableHistoryCell} key={`row-skeleton-${row.id}`}>
            <Skeleton
                animation="wave"
                variant="rect"
                height={40}
                style={{marginBottom: 8}}
                classes={{root: classes.tableSkeleton}}
            />
          </TableCell>
        ))}
        </TableRow>
      ))
      }
    </>
  )
};

const EmptyStateTasksBulkHistory = () => {
  const classes = useBulkTasksStyles();
  return (
    <TableRow hover tabIndex={-1} key="empty-data-buk-history">
      <TableCell align='center' rowSpan={3} colSpan={4} className={classes.tableHistoryCell}>
        <Trans>No Bulk Task History</Trans>
      </TableCell>
    </TableRow>
  )
}

const BodyRowRenderer = () => {
  const classes = useBulkTasksStyles();
  const tasksBulkHistory = useSelector(selectTasksBulkHistory);

  if(tasksBulkHistory.length === 0) return <EmptyStateTasksBulkHistory/>;

  return(
    <>
      {tasksBulkHistory.map((row) => {
        return (
          <TableRow hover tabIndex={-1} key={row.id}>
            {columns.map((column) => {
              const value = row[column.id];
              return (
                <TableCell key={column.id} align={column.align} className={classes.tableHistoryCell}>
                  {column?.customCell ? column.customCell(value as string, row.fileName as string) : value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  )
};

const TableBodyTasksBulkHistory = () => {
  const isFetching = useSelector(selectTasksBulkHistoryFetching);
  if(isFetching) return <BodyRowSkeletonRenderer />
  
  return <BodyRowRenderer />
}

export const TasksBulkHistory = () => {
  const classes = useBulkTasksStyles();

  return (
    <Box mt={2}>
      <StyledBulkTasksPanelTitle variant="h5"><Box><Trans>Bulk Task History</Trans></Box></StyledBulkTasksPanelTitle>
      <Paper className={classes.rootPaper}>
        <TableContainer className={classes.historyTableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.width }}
                    className={classes.tableHeader}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableHistoryBody}>
              <TableBodyTasksBulkHistory />
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <RemoveBulkTask />
    </Box>
  );
}
