import { Box, Fade, Theme, Tooltip, makeStyles, styled } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import { UserAvatarInfo } from 'components/userInfo/UserAvatarInfo';
import { StoreTaskChecklistModel } from 'api/task/storeTask/storeTaskModel';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { fullDateByBrowser } from 'utils/helpers/dateHelper';
import { useMemo } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    width: 50
  },
}));

type StoreTaskChecklistAvatarCompletedProps = {
  checklistItem: StoreTaskChecklistModel
};

export const StoreTaskChecklistAvatarCompleted: React.FC<StoreTaskChecklistAvatarCompletedProps> = ({ checklistItem }) => {
  const classes = useStyles();
  const isTasksChecklistAuditEnabled = useFeatureState(FeatureFlags.TasksChecklistAudit);
  const hideChecklistAudit = useMemo(() =>
    !checklistItem?.completedBy || !checklistItem?.completedAt || !isTasksChecklistAuditEnabled || !checklistItem?.completed
  ,[isTasksChecklistAuditEnabled, checklistItem])

  return (
    <Box className={clsx({[classes.container]: isTasksChecklistAuditEnabled})}>
      {!hideChecklistAudit &&
        <>
          <UserAvatarInfo
            userId={checklistItem.completedBy as string}
            avatarSize={22}
          />
          <Tooltip title={fullDateByBrowser(checklistItem.completedAt as string)} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
              <TimerIcon />
          </Tooltip>
        </>
      }
    </Box>
  )
}


export const StyledBoxChecklistAudit = styled(Box)(({ theme }) => ({
  width: 50,
  minWidth: 50
}));
